<template>
    <div class="col-12 text-center" v-if="brandUrl">
        <p class="mt-5 small">{{$t('redirect-in-seconds')}}.</p>
        <a :href="brandUrl" class="btn btn-light mt-1">{{$t('redirect')}}</a>
    </div>
</template>

<script>
     export default {
        name: 'RedirectButton',
        props: {
            brandUrl: String
        },
        mounted() {
            const _this = this;
            setTimeout(function(){
                window.location.href = _this.brandUrl;
			},10000);
        },

    }
</script>

<style scoped></style>
